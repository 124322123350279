<script lang="ts" setup>
const isOpen = defineModel();

defineProps({
  title: String,
  footer: Boolean,
});
</script>

<template>
  <UModal v-model="isOpen">
    <UCard
      :ui="{
        ring: '',
        divide: 'divide-y divide-gray-100 dark:divide-gray-800',
      }">
      <template v-if="title" #header>
        <div class="flex items-center justify-between">
          <h3
            class="text-base font-semibold leading-6 text-gray-900 dark:text-mercury-500">
            {{ title }}
          </h3>
          <UButton
            color="gray"
            variant="ghost"
            icon="i-heroicons-x-mark-20-solid"
            class="-my-1"
            @click="isOpen = false" />
        </div>
      </template>

      <slot></slot>

      <template #footer v-if="footer">
        <slot name="footer"></slot>
      </template>
    </UCard>
  </UModal>
</template>

<style></style>
